import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Row, StyledDisclaimerText, StyledSectionHeader, StyledButton, StyledButtonText,
} from '../../components/Layout';
import MerchantLayout from '../../components/Layout/MerchantLayout';
import HappyHeart from '../../images/happyHeart.svg';
import MerchantLink from '../../components/MerchantLink';
import merchants from '../../merchants.json';

const StyledHappySmallHeart = styled(HappyHeart)`
  margin-top: 5px;
  margin-bottom: 25px;
  width: 50%;  
`;
const StyledMerchantLogo = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 60%;
`;

const StyledText = styled.p`
  margin: 20px 0px 20px 0px;
  font-size: 18px;
`;

const StyledA = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 100%;
`;

function SimpleSadMerchant({ websiteUrl }) {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <StyledSectionHeader>
          {t('merchant.sent.title')}
        </StyledSectionHeader>
      </Row>
      <Row>
        <StyledDisclaimerText>
          {t('merchant.sent.body')}
        </StyledDisclaimerText>
      </Row>
      <StyledHappySmallHeart />
      <MerchantLink websiteUrl={websiteUrl} />
    </>
  );
}

function CustomSadMerchant({ customSad, voucherCode, websiteUrl }) {
  const { i18n } = useTranslation();
  const text = customSad.text[i18n.language];

  return (
    <>
      <Row>
        <StyledSectionHeader>
          {text[0]}
        </StyledSectionHeader>
      </Row>
      <Row>
        <StyledText>
          {text[1]}
          &nbsp;
          <b>{voucherCode}</b>
        </StyledText>
      </Row>
      <StyledHappySmallHeart />
      <Row>
        <StyledA target="_blank" rel="noreferrer" href={websiteUrl}>
          <StyledButton>
            <StyledButtonText>
              {text[2]}
            </StyledButtonText>
          </StyledButton>
        </StyledA>
      </Row>
    </>
  );
}

function IndexPage({ location }) {
  const { state = {} } = location;

  const { merchant = '' } = state;
  const merchantConfig = merchants[merchant] || {};
  const {
    customSad, voucherCode, logoUrls = {}, websiteUrl = '',
  } = merchantConfig;

  return (
    <MerchantLayout merchant={merchant}>
      {customSad
        ? (
          <CustomSadMerchant
            customSad={customSad}
            voucherCode={voucherCode}
            websiteUrl={websiteUrl}
          />
        )
        : <SimpleSadMerchant websiteUrl={websiteUrl} />}
      <StyledMerchantLogo src={logoUrls.black} />
    </MerchantLayout>
  );
}

export default IndexPage;
